import React from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import './index.css'
import App from 'containers/App'
import reportWebVitals from './reportWebVitals'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import MommomEnv from './services/MommomEnv'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

Sentry.init({
    dsn: MommomEnv.isProduction ? process.env.REACT_APP_SENTRY_DSN : undefined,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0,
})

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 10 * 1000, // 1분 동안 캐시를 유지
            refetchOnWindowFocus: true, // 창이 다시 포커싱 될 때마다 새로고침
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <QueryClientProvider client={queryClient}>
        <RecoilRoot>
            <App />
        </RecoilRoot>
    </QueryClientProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
